//AUTH CONSTANTS

export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAILURE = "USER_REGISTER_FAILURE";

export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAILURE = "USER_LOGIN_FAILURE";

export const USER_MAIL_REQUEST = "USER_MAIL_REQUEST";
export const USER_MAIL_SUCCESS = "USER_MAIL_SUCCESS";
export const USER_MAIL_FAILURE = "USER_MAIL_FAILURE";

export const USER_RESET_REQUEST = "USER_RESET_REQUEST";
export const USER_RESET_SUCCESS = "USER_RESET_SUCCESS";
export const USER_RESET_FAILURE = "USER_RESET_FAILURE";

export const USER_CHANGE_PASS_REQUEST = "USER_CHANGE_PASS_REQUEST";
export const USER_CHANGE_PASS_SUCCESS = "USER_CHANGE_PASS_SUCCESS";
export const USER_CHANGE_PASS_FAILURE = "USER_CHANGE_PASS_FAILURE";

//ROLE BASED CONSTATNTS
export const USER_ADD_REQUEST = "USER_ADD_REQUEST";
export const USER_ADD_SUCCESS = "USER_ADD_SUCCESS";
export const USER_ADD_FAILURE = "USER_ADD_FAILURE";

export const USER_FETCH_ROLE_REQUEST = "USER_FETCH_ROLE_REQUEST";
export const USER_FETCH_ROLE_SUCCESS = "USER_FETCH_ROLE_SUCCESS";
export const USER_FETCH_ROLE_FAILURE = "USER_FETCH_ROLE_FAILURE";

export const USER_FETCH_ID_REQUEST = "USER_FETCH_ID_REQUEST";
export const USER_FETCH_ID_SUCCESS = "USER_FETCH_ID_SUCCESS";
export const USER_FETCH_ID_FAILURE = "USER_FETCH_ID_FAILURE";

export const USER_DELETE_REQUEST = "USER_DELETE_REQUEST";
export const USER_DELETE_SUCCESS = "USER_DELETE_SUCCESS";
export const USER_DELETE_FAILURE = "USER_DELETE_FAILURE";

export const USER_UPDATE_REQUEST = "USER_UPDATE_REQUEST";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_UPDATE_FAILURE = "USER_UPDATE_FAILURE";

export const USER_APPROVAL_STATUS_REQUEST = "USER_APPROVAL_STATUS_REQUEST";
export const USER_APPROVAL_STATUS_SUCCESS = "USER_APPROVAL_STATUS_SUCCESS";
export const USER_APPROVAL_STATUS_FAILURE = "USER_APPROVAL_STATUS_FAILURE";

export const LOGIN_USER_FETCH_ID_REQUEST = "LOGIN_USER_FETCH_ID_REQUEST";
export const LOGIN_USER_FETCH_ID_SUCCESS = "LOGIN_USER_FETCH_ID_SUCCESS";
export const LOGIN_USER_FETCH_ID_FAILURE = "LOGIN_USER_FETCH_ID_FAILURE";

//STATUS BASED CONSTATNTS
export const USER_STATUS_REQUEST = "USER_STATUS_REQUEST";
export const USER_STATUS_SUCCESS = "USER_STATUS_SUCCESS";
export const USER_STATUS_FAILURE = "USER_STATUS_FAILURE";

export const USER_STATUS_FOR_REQUEST = "USER_STATUS_FOR_REQUEST";
export const USER_STATUS_FOR_SUCCESS = "USER_STATUS_FOR_SUCCESS";
export const USER_STATUS_FOR_FAILURE = "USER_STATUS_FOR_FAILURE";

export const USER_STATUS_CREATE_REQUEST = "USER_STATUS_CREATE_REQUEST";
export const USER_STATUS_CREATE_SUCCESS = "USER_STATUS_CREATE_SUCCESS";
export const USER_STATUS_CREATE_FAILURE = "USER_STATUS_CREATE_FAILURE";

export const USER_STATUS_UPDATE_REQUEST = "USER_STATUS_UPDATE_REQUEST";
export const USER_STATUS_UPDATE_SUCCESS = "USER_STATUS_UPDATE_SUCCESS";
export const USER_STATUS_UPDATE_FAILURE = "USER_STATUS_UPDATE_FAILURE";

export const USER_STATUS_DELETE_REQUEST = "USER_STATUS_DELETE_REQUEST";
export const USER_STATUS_DELETE_SUCCESS = "USER_STATUS_DELETE_SUCCESS";
export const USER_STATUS_DELETE_FAILURE = "USER_STATUS_DELETE_FAILURE";

//NEWS CONSTATNTS
export const NEWS_ADD_REQUEST = "NEWS_ADD_REQUEST";
export const NEWS_ADD_SUCCESS = "NEWS_ADD_SUCCESS";
export const NEWS_ADD_FAILURE = "NEWS_ADD_FAILURE";

export const NEWS_GET_REQUEST = "NEWS_GET_REQUEST";
export const NEWS_GET_SUCCESS = "NEWS_GET_SUCCESS";
export const NEWS_GET_FAILURE = "NEWS_GET_FAILURE";

//News Delete
export const NEWS_DELETE_REQUEST = "NEWS_DELETE_REQUEST";
export const NEWS_DELETE_SUCCESS = "NEWS_DELETE_SUCCESS";
export const NEWS_DELETE_FAILURE = "NEWS_DELETE_FAILURE";

//News Update
export const NEWS_UPDATE_REQUEST = "NEWS_UPDATE_REQUEST";
export const NEWS_UPDATE_SUCCESS = "NEWS_UPDATE_SUCCESS";
export const NEWS_UPDATE_FAILURE = "NEWS_UPDATE_FAILURE";

//EXAMS CONSTATNTS
export const EXAMS_ADD_REQUEST = "EXAMS_ADD_REQUEST";
export const EXAMS_ADD_SUCCESS = "EXAMS_ADD_SUCCESS";
export const EXAMS_ADD_FAILURE = "EXAMS_ADD_FAILURE";

export const EXAM_GET_REQUEST = "EXAM_GET_REQUEST";
export const EXAM_GET_SUCCESS = "EXAM_GET_SUCCESS";
export const EXAM_GET_FAILURE = "EXAM_GET_FAILURE";

//Delete Exam
export const EXAM_DELETE_REQUEST = "EXAM_DELETE_REQUEST";
export const EXAM_DELETE_SUCCESS = "EXAM_DELETE_SUCCESS";
export const EXAM_DELETE_FAILURE = "EXAM_DELETE_FAILURE";

//Update Exam
export const EXAM_UPDATE_REQUEST = "EXAM_UPDATE_REQUEST";
export const EXAM_UPDATE_SUCCESS = "EXAM_UPDATE_SUCCESS";
export const EXAM_UPDATE_FAILURE = "EXAM_UPDATE_FAILURE";

//PROPERTY CREATE
export const PROPERTY_ADD_REQUEST = "PROPERTY_ADD_REQUEST";
export const PROPERTY_ADD_SUCCESS = "PROPERTY_ADD_SUCCESS";
export const PROPERTY_ADD_FAILURE = "PROPERTY_ADD_FAILURE";

//PROPERTY GET
export const PROPERTY_GET_REQUEST = "PROPERTY_GET_REQUEST";
export const PROPERTY_GET_SUCCESS = "PROPERTY_GET_SUCCESS";
export const PROPERTY_GET_FAILURE = "PROPERTY_GET_FAILURE";

//Delete Exam
export const PROPERTY_DELETE_REQUEST = "PROPERTY_DELETE_REQUEST";
export const PROPERTY_DELETE_SUCCESS = "PROPERTY_DELETE_SUCCESS";
export const PROPERTY_DELETE_FAILURE = "PROPERTY_DELETE_FAILURE";

//Create Gallery
export const PROPERTY_GALLERY_ADD_REQUEST = "PROPERTY_GALLERY_ADD_REQUEST";
export const PROPERTY_GALLERY_ADD_SUCCESS = "PROPERTY_GALLERY_ADD_SUCCESS";
export const PROPERTY_GALLERY_ADD_FAILURE = "PROPERTY_GALLERY_ADD_FAILURE";

//Get Gallery
export const GET_GALLERY_LIST = "GET_GALLERY_LIST";
export const SUCCESS_GALLERY_LIST = "SUCCESS_GALLERY_LIST";
export const FAILURE_GALLERY_LIST = "FAILURE_GALLERY_LIST";

//Update Gallery
export const EDIT_GALLERY_IMAGE_FAILURE = "EDIT_GALLERY_IMAGE_FAILURE";
export const EDIT_GALLERY_IMAGE_SUCCESS = "EDIT_GALLERY_IMAGE_SUCCESS";
export const EDIT_GALLERY_IMAGE_REQUEST = "EDIT_GALLERY_IMAGE_REQUEST";

//Delete Gallery
export const DELETE_GALLERY_IMAGE_REQUEST = "DELETE_GALLERY_IMAGE_REQUEST";
export const DELETE_GALLERY_IMAGE_SUCCESS = "DELETE_GALLERY_IMAGE_SUCCESS";
export const DELETE_GALLERY_IMAGE_FAILURE = "DELETE_GALLERY_IMAGE_FAILURE";

//Replace Gallery
export const REPLACE_GALLERY_IMAGE_REQUEST = "REPLACE_GALLERY_IMAGE_REQUEST";
export const REPLACE_GALLERY_IMAGE_SUCCESS = "REPLACE_GALLERY_IMAGE_SUCCESS";
export const REPLACE_GALLERY_IMAGE_FAILURE = "REPLACE_GALLERY_IMAGE_FAILURE";

//Get FAQ
export const GET_FAQS_LIST = "GET_FAQS_LIST";
export const SUCCESS_FAQS_LIST = "SUCCESS_FAQS_LIST";
export const FAILURE_FAQS_LIST = "FAILURE_FAQS_LIST";

//REVIEW CREATE
export const REVIEW_ADD_REQUEST = "REVIEW_ADD_REQUEST";
export const REVIEW_ADD_SUCCESS = "REVIEW_ADD_SUCCESS";
export const REVIEW_ADD_FAILURE = "REVIEW_ADD_FAILURE";

//REVIEW FETCH
export const GET_REVIEWS_LIST = "GET_REVIEWS_LIST";
export const SUCCESS_REVIEWS_LIST = "SUCCESS_REVIEWS_LIST";
export const FAILURE_REVIEWS_LIST = "FAILURE_REVIEWS_LIST";

//Delete Review
export const REVIEW_DELETE_SUCCESS = "REVIEW_DELETE_SUCCESS";
export const REVIEW_DELETE_FAILURE = "REVIEW_DELETE_FAILURE";
export const REVIEW_DELETE_REQUEST = "REVIEW_DELETE_REQUEST";

//Update Review
export const REVIEW_UPDATE_FAILURE = "TREVIEW_UPDATE_FAILURE";
export const REVIEW_UPDATE_SUCCESS = "REVIEW_UPDATE_SUCCESS";
export const REVIEW_UPDATE_REQUEST = "REVIEW_UPDATE_REQUEST";

//Create TeamLeader
export const TEAM_LEADER_ADD_REQUEST = "TEAM_LEADER_ADD_REQUEST";
export const TEAM_LEADER_ADD_SUCCESS = "TEAM_LEADER_ADD_SUCCESS";
export const TEAM_LEADER_ADD_FAILURE = "TEAM_LEADER_ADD_FAILURE";

//Get Teamleader
export const TEAM_LEADER_GET_REQUEST = "TEAM_LEADER_GET_REQUEST";
export const TEAM_LEADER_GET_FAILURE = "TEAM_LEADER_GET_FAILURE";
export const TEAM_LEADER_GET_SUCCESS = "TEAM_LEADER_GET_SUCCESS";

//Delete Team Leader
export const TEAM_LEAD_DELETE_SUCCESS = "TEAM_LEAD_DELETE_SUCCESS";
export const TEAM_LEAD_DELETE_FAILURE = "TEAM_LEAD_DELETE_FAILURE";
export const TEAM_LEAD_DELETE_REQUEST = "TEAM_LEAD_DELETE_REQUEST";

//Updtae Team Leader
export const TEAM_LEAD_UPDATE_FAILURE = "TEAM_LEAD_UPDATE_FAILURE";
export const TEAM_LEAD_UPDATE_SUCCESS = "TEAM_LEAD_UPDATE_SUCCESS";
export const TEAM_LEAD_UPDATE_REQUEST = "TEAM_LEAD_UPDATE_REQUEST";

//Add SEO
export const SEO_ADD_REQUEST = "SEO_ADD_REQUEST";
export const SEO_ADD_SUCCESS = "SEO_ADD_SUCCESS";
export const SEO_ADD_FAILURE = "SEO_ADD_FAILURE";

//Get SEO
export const SEO_GET_REQUEST = "SEO_GET_REQUEST";
export const SEO_GET_FAILURE = "SEO_GET_FAILURE";
export const SEO_GET_SUCCESS = "SEO_GET_SUCCESS";

//Delete SEO
export const SEO_DELETE_SUCCESS = "SEO_DELETE_SUCCESS";
export const SEO_DELETE_FAILURE = "SEO_DELETE_FAILURE";
export const SEO_DELETE_REQUEST = "SEO_DELETE_REQUEST";

//Update Seo
export const SEO_UPDATE_FAILURE = "SEO_UPDATE_FAILURE";
export const SEO_UPDATE_SUCCESS = "SEO_UPDATE_SUCCESS";
export const SEO_UPDATE_REQUEST = "SEO_UPDATE_REQUEST";

/////////////////////////////////////////////////////////////////////////////////////////////////////////////

//PROPERTY BASED CONSTATNTS

export const PROPERTY_CREATE_REQUEST = "PROPERTY_CREATE_REQUEST";
export const PROPERTY_CREATE_SUCCESS = "PROPERTY_CREATE_SUCCESS";
export const PROPERTY_CREATE_FAILURE = "PROPERTY_CREATE_FAILURE";

// export const PROPERTY_DELETE_REQUEST = 'PROPERTY_DELETE_REQUEST';
// export const PROPERTY_DELETE_SUCCESS = 'PROPERTY_DELETE_SUCCESS';
// export const PROPERTY_DELETE_FAILURE = 'PROPERTY_DELETE_FAILURE';

export const PROPERTY_UPDATE_REQUEST = "PROPERTY_UPDATE_REQUEST";
export const PROPERTY_UPDATE_SUCCESS = "PROPERTY_UPDATE_SUCCESS";
export const PROPERTY_UPDATE_FAILURE = "PROPERTY_UPDATE_FAILURE";

// export const USER_STATUS_UPDATE_REQUEST = 'PROPERTY_UPDATE_REQUEST';
// export const PROPERTY_UPDATE_SUCCESS = 'PROPERTY_UPDATE_SUCCESS';
// export const PROPERTY_UPDATE_FAILURE = 'PROPERTY_UPDATE_FAILURE';

// export const USER_STATUS_DELETE_REQUEST = 'USER_STATUS_DELETE_REQUEST';
// export const USER_STATUS_DELETE_SUCCESS = 'USER_STATUS_DELETE_SUCCESS';
// export const USER_STATUS_DELETE_FAILURE = 'USER_STATUS_DELETE_FAILURE';

//PROPERTY BASED CONSTATNTS

export const PROPERTY_TYPE_ADD_REQUEST = "PROPERTY_TYPE_ADD_REQUEST";
export const PROPERTY_TYPE_ADD_SUCCESS = "PROPERTY_TYPE_ADD_SUCCESS";
export const PROPERTY_TYPE_ADD_FAILURE = "PROPERTY_TYPE_ADD_FAILURE";

export const PROPERTY_TYPE_GET_REQUEST = "PROPERTY_TYPE_GET_REQUEST";
export const PROPERTY_TYPE_GET_SUCCESS = "PROPERTY_TYPE_GET_SUCCESS";
export const PROPERTY_TYPE_GET_FAILURE = "PROPERTY_TYPE_GET_FAILURE";

export const PROPERTY_TYPE_UPDATE_REQUEST = "PROPERTY_TYPE_UPDATE_REQUEST";
export const PROPERTY_TYPE_UPDATE_SUCCESS = "PROPERTY_TYPE_UPDATE_SUCCESS";
export const PROPERTY_TYPE_UPDATE_FAILURE = "PROPERTY_TYPE_UPDATE_FAILURE";

export const PROPERTY_TYPE_DELETE_REQUEST = "PROPERTY_TYPE_DELETE_REQUEST";
export const PROPERTY_TYPE_DELETE_SUCCESS = "PROPERTY_TYPE_DELETE_SUCCESS";
export const PROPERTY_TYPE_DELETE_FAILURE = "PROPERTY_TYPE_DELETE_FAILURE";

export const PROPERTY_FORM_REQUEST = "PROPERTY_FORM_REQUEST";
export const PROPERTY_FORM_SUCCESS = "PROPERTY_FORM_SUCCESS";
export const PROPERTY_FORM_FAILURE = "PROPERTY_FORM_FAILURE";

export const GET_AFFILIATE_APPROVE = "GET_AFFILIATE_APPROVE";
export const SUCCESS_AFFILIATE_APPROVE = "SUCCESS_AFFILIATE_APPROVE";
export const FAILURE_AFFILIATE_APPROVE = "FAILURE_AFFILIATE_APPROVE";

export const GET_COLLEGE_LIST = "GET_COLLEGE_LIST";
export const SUCCESS_COLLEGE_LIST = "SUCCESS_COLLEGE_LIST";
export const FAILURE_COLLEGE_LIST = "FAILURE_COLLEGE_LIST";

export const PROPERTY_TEAM_ADD_REQUEST = "PROPERTY_TEAM_ADD_REQUEST";
export const PROPERTY_TEAM_ADD_SUCCESS = "PROPERTY_TEAM_ADD_SUCCESS";
export const PROPERTY_TEAM_ADD_FAILURE = "PROPERTY_TEAM_ADD_FAILURE";

export const PROPERTY_TEAM_UPDATE_REQUEST = "PROPERTY_TEAM_UPDATE_REQUEST";
export const PROPERTY_TEAM_UPDATE_SUCCESS = "PROPERTY_TEAM_UPDATE_SUCCESS";
export const PROPERTY_TEAM_UPDATE_FAILURE = "PROPERTY_TEAM_UPDATE_FAILURE";

export const GET_TEAM_LIST = "GET_TEAM_LIST";
export const SUCCESS_TEAM_LIST = "SUCCESS_TEAM_LIST";
export const FAILURE_TEAM_LIST = "FAILURE_TEAM_LIST";

export const GET_PLACEMENT_LIST = "GET_PLACEMENT_LIST";
export const SUCCESS_PLACEMENT_LIST = "SUCCESS_PLACEMENT_LIST";
export const FAILURE_PLACEMENT_LIST = "FAILURE_PLACEMENT_LIST";

export const GET_ANNOUNCEMENT_LIST = "GET_ANNOUNCEMENT_LIST";
export const SUCCESS_ANNOUNCEMENT_LIST = "SUCCESS_ANNOUNCEMENT_LIST";
export const FAILURE_ANNOUNCEMENT_LIST = "FAILURE_ANNOUNCEMENT_LIST";

export const GET_SCHOLARSHIP_LIST = "GET_SCHOLARSHIP_LIST";
export const SUCCESS_SCHOLARSHIP_LIST = "SUCCESS_SCHOLARSHIP_LIST";
export const FAILURE_SCHOLARSHIP_LIST = "FAILURE_SCHOLARSHIP_LIST";

export const GET_LOAN_LIST = "GET_LOAN_LIST";
export const SUCCESS_LOAN_LIST = "SUCCESS_LOAN_LIST";
export const FAILURE_LOAN_LIST = "FAILURE_LOAN_LIST";

export const GET_ADMISSION_PROCESS_LIST = "GET_ADMISSION_PROCESS_LIST";
export const SUCCESS_ADMISSION_PROCESS_LIST = "SUCCESS_ADMISSION_PROCESS_LIST";
export const FAILURE_ADMISSION_PROCESS_LIST = "FAILURE_ADMISSION_PROCESS_LIST";

export const PROPERTY_PLACEMENT_ADD_REQUEST = "PROPERTY_PLACEMENT_ADD_REQUEST";
export const PROPERTY_PLACEMENT_ADD_SUCCESS = "PROPERTY_PLACEMENT_ADD_SUCCESS";
export const PROPERTY_PLACEMENT_ADD_FAILURE = "PROPERTY_PLACEMENT_ADD_FAILURE";

export const PROPERTY_PLACEMENT_UPDATE_REQUEST =
  "PROPERTY_PLACEMENT_UPDATE_REQUEST";
export const PROPERTY_PLACEMENT_UPDATE_SUCCESS =
  "PROPERTY_PLACEMENT_UPDATE_SUCCESS";
export const PROPERTY_PLACEMENT_UPDATE_FAILURE =
  "PROPERTY_PLACEMENT_UPDATE_FAILURE";

export const PROPERTY_LOAN_ADD_REQUEST = "PROPERTY_LOAN_ADD_REQUEST";
export const PROPERTY_LOAN_ADD_SUCCESS = "PROPERTY_LOAN_ADD_SUCCESS";
export const PROPERTY_LOAN_ADD_FAILURE = "PROPERTY_LOAN_ADD_FAILURE";

export const PROPERTY_LOAN_UPDATE_REQUEST = "PROPERTY_LOAN_UPDATE_REQUEST";
export const PROPERTY_LOAN_UPDATE_SUCCESS = "PROPERTY_LOAN_UPDATE_SUCCESS";
export const PROPERTY_LOAN_UPDATE_FAILURE = "PROPERTY_LOAN_UPDATE_FAILURE";

export const PROPERTY_SCHOLARSHIP_ADD_REQUEST =
  "PROPERTY_SCHOLARSHIP_ADD_REQUEST";
export const PROPERTY_SCHOLARSHIP_ADD_SUCCESS =
  "PROPERTY_SCHOLARSHIP_ADD_SUCCESS";
export const PROPERTY_SCHOLARSHIP_ADD_FAILURE =
  "PROPERTY_SCHOLARSHIP_ADD_FAILURE";

export const PROPERTY_SCHOLARSHIP_UPDATE_REQUEST =
  "PROPERTY_SCHOLARSHIP_UPDATE_REQUEST";
export const PROPERTY_SCHOLARSHIP_UPDATE_SUCCESS =
  "PROPERTY_SCHOLARSHIP_UPDATE_SUCCESS";
export const PROPERTY_SCHOLARSHIP_UPDATE_FAILURE =
  "PROPERTY_SCHOLARSHIP_UPDATE_FAILURE";

export const PROPERTY_ADMISSION_PROCESS_ADD_REQUEST =
  "PROPERTY_ADMISSION_PROCESS_ADD_REQUEST";
export const PROPERTY_ADMISSION_PROCESS_ADD_SUCCESS =
  "PROPERTY_ADMISSION_PROCESS_ADD_SUCCESS";
export const PROPERTY_ADMISSION_PROCESS_ADD_FAILURE =
  "PROPERTY_ADMISSION_PROCESS_ADD_FAILURE";

export const PROPERTY_ADMISSION_PROCESS_UPDATE_REQUEST =
  "PROPERTY_ADMISSION_PROCESS_UPDATE_REQUEST";
export const PROPERTY_ADMISSION_PROCESS_UPDATE_SUCCESS =
  "PROPERTY_ADMISSION_PROCESS_UPDATE_SUCCESS";
export const PROPERTY_ADMISSION_PROCESS_UPDATE_FAILURE =
  "PROPERTY_ADMISSION_PROCESS_UPDATE_FAILURE";

export const PROPERTY_ANNOUNCEMENT_ADD_REQUEST =
  "PROPERTY_ANNOUNCEMENT_ADD_REQUEST";
export const PROPERTY_ANNOUNCEMENT_ADD_SUCCESS =
  "PROPERTY_ANNOUNCEMENT_ADD_SUCCESS";
export const PROPERTY_ANNOUNCEMENT_ADD_FAILURE =
  "PROPERTY_ANNOUNCEMENT_ADD_FAILURE";

export const PROPERTY_ANNOUNCEMENT_UPDATE_REQUEST =
  "PROPERTY_ANNOUNCEMENT_UPDATE_REQUEST";
export const PROPERTY_ANNOUNCEMENT_UPDATE_SUCCESS =
  "PROPERTY_ANNOUNCEMENT_UPDATE_SUCCESS";
export const PROPERTY_ANNOUNCEMENT_UPDATE_FAILURE =
  "PROPERTY_ANNOUNCEMENT_UPDATE_FAILURE";

export const PROPERTY_FAQS_ADD_REQUEST = "PROPERTY_FAQS_ADD_REQUEST";
export const PROPERTY_FAQS_ADD_SUCCESS = "PROPERTY_FAQS_ADD_SUCCESS";
export const PROPERTY_FAQS_ADD_FAILURE = "PROPERTY_FAQS_ADD_FAILURE";

export const PROPERTY_FAQS_UPDATE_REQUEST = "PROPERTY_FAQS_UPDATE_REQUEST";
export const PROPERTY_FAQS_UPDATE_SUCCESS = "PROPERTY_FAQS_UPDATE_SUCCESS";
export const PROPERTY_FAQS_UPDATE_FAILURE = "PROPERTY_FAQS_UPDATE_FAILURE";

export const GET_QAS_LIST = "GET_QAS_LIST";
export const SUCCESS_QAS_LIST = "SUCCESS_QAS_LIST";
export const FAILURE_QAS_LIST = "FAILURE_QAS_LIST";

export const CATEGORY_ADD_REQUEST = "CATEGORY_ADD_REQUEST";
export const CATEGORY_ADD_SUCCESS = "CATEGORY_ADD_SUCCESS";
export const CATEGORY_ADD_FAILURE = "CATEGORY_ADD_FAILURE";

export const CATEGORY_GET_REQUEST = "CATEGORY_GET_REQUEST";
export const CATEGORY_GET_SUCCESS = "CATEGORY_GET_SUCCESS";
export const CATEGORY_GET_FAILURE = "CATEGORY_GET_FAILURE";

export const CATEGORY_DELETE_FAILURE = "CATEGORY_DELETE_FAILURE";
export const CATEGORY_DELETE_REQUEST = "CATEGORY_DELETE_REQUEST";
export const CATEGORY_DELETE_SUCCESS = "CATEGORY_DELETE_FAILURE";

export const CATEGORY_SOFT_DELETE_FAILURE = "CATEGORY_SOFT_DELETE_FAILURE";
export const CATEGORY_SOFT_DELETE_REQUEST = "CATEGORY_SOFT_DELETE_REQUEST";
export const CATEGORY_SOFT_DELETE_SUCCESS = "CATEGORY_SOFT_DELETE_SUCCESS";

export const CATEGORY_UPDATE_REQUEST = "CATEGORY_UPDATE_REQUEST";
export const CATEGORY_UPDATE_SUCCESS = "CATEGORY_UPDATE_SUCCESS";
export const CATEGORY_UPDATE_FAILURE = "CATEGORY_UPDATE_FAILURE";

export const PROPERTY_OTHERS_ADD_REQUEST = "PROPERTY_OTHERS_ADD_REQUEST";
export const PROPERTY_OTHERS_ADD_SUCCESS = "PROPERTY_OTHERS_ADD_SUCCESS";
export const PROPERTY_OTHERS_ADD_FAILURE = "PROPERTY_OTHERS_ADD_FAILURE";

export const PROPERTY_OTHERS_UPDATE_REQUEST = "PROPERTY_OTHERS_UPDATE_REQUEST";
export const PROPERTY_OTHERS_UPDATE_SUCCESS = "PROPERTY_OTHERS_UPDATE_SUCCESS";
export const PROPERTY_OTHERS_UPDATE_FAILURE = "PROPERTY_OTHERS_UPDATE_FAILURE";

export const PROPERTY_OTHERS_GET_REQUEST = "PROPERTY_OTHERS_GET_REQUEST";
export const PROPERTY_OTHERS_GET_SUCCESS = "PROPERTY_OTHERS_GET_SUCCESS";
export const PROPERTY_OTHERS_GET_FAILURE = "PROPERTY_OTHERS_GET_FAILURE";

export const USER_PROFILEUPDATE_REQUEST = "USER_PROFILEUPDATE_REQUEST";
export const USER_PROFILEUPDATE_SUCCESS = "USER_PROFILEUPDATE_SUCCESS";
export const USER_PROFILEUPDATE_FAILURE = "USER_PROFILEUPDATE_FAILURE";

/////////////////////////////////////////////////////////////
export const COURSE_ADD_REQUEST = "COURSE_ADD_REQUEST";
export const COURSE_ADD_SUCCESS = "COURSE_ADD_SUCCESS";
export const COURSE_ADD_FAILURE = "COURSE_ADD_FAILURE";

export const COURSE_GET_REQUEST = "COURSE_GET_REQUEST";
export const COURSE_GET_SUCCESS = "COURSE_GET_SUCCESS";
export const COURSE_GET_FAILURE = "COURSE_GET_FAILURE";

export const COURSE_UPDATE_REQUEST = "COURSE_UPDATE_REQUEST";
export const COURSE_UPDATE_SUCCESS = "COURSE_UPDATE_SUCCESS";
export const COURSE_UPDATE_FAILURE = "COURSE_UPDATE_FAILURE";

export const COURSE_DELETE_REQUEST = "COURSE_DELETE_REQUEST";
export const COURSE_DELETE_SUCCESS = "COURSE_DELETE_SUCCESS";
export const COURSE_DELETE_FAILURE = "COURSE_DELETE_FAILURE";

export const DEPARTMENT_ADD_REQUEST = "DEPARTMENT_ADD_REQUEST";
export const DEPARTMENT_ADD_SUCCESS = "DEPARTMENT_ADD_SUCCESS";
export const DEPARTMENT_ADD_FAILURE = "DEPARTMENT_ADD_FAILURE";

export const DEPARTMENT_GET_REQUEST = "DEPARTMENT_GET_REQUEST";
export const DEPARTMENT_GET_SUCCESS = "DEPARTMENT_GET_SUCCESS";
export const DEPARTMENT_GET_FAILURE = "DEPARTMENT_GET_FAILURE";

export const DEPARTMENT_UPDATE_REQUEST = "DEPARTMENT_UPDATE_REQUEST";
export const DEPARTMENT_UPDATE_SUCCESS = "DEPARTMENT_UPDATE_SUCCESS";
export const DEPARTMENT_UPDATE_FAILURE = "DEPARTMENT_UPDATE_FAILURE";

export const DEPARTMENT_DELETE_REQUEST = "DEPARTMENT_DELETE_REQUEST";
export const DEPARTMENT_DELETE_SUCCESS = "DEPARTMENT_DELETE_SUCCESS";
export const DEPARTMENT_DELETE_FAILURE = "DEPARTMENT_DELETE_FAILURE";

export const SUBJECT_ADD_REQUEST = "SUBJECT_ADD_REQUEST";
export const SUBJECT_ADD_SUCCESS = "SUBJECT_ADD_SUCCESS";
export const SUBJECT_ADD_FAILURE = "SUBJECT_ADD_FAILURE";

export const SUBJECT_GET_REQUEST = "SUBJECT_GET_REQUEST";
export const SUBJECT_GET_SUCCESS = "SUBJECT_GET_SUCCESS";
export const SUBJECT_GET_FAILURE = "SUBJECT_GET_FAILURE";

export const SUBJECT_UPDATE_REQUEST = "SUBJECT_UPDATE_REQUEST";
export const SUBJECT_UPDATE_SUCCESS = "SUBJECT_UPDATE_SUCCESS";
export const SUBJECT_UPDATE_FAILURE = "SUBJECT_UPDATE_FAILURE";

export const SUBJECT_DELETE_REQUEST = "SUBJECT_DELETE_REQUEST";
export const SUBJECT_DELETE_SUCCESS = "SUBJECT_DELETE_SUCCESS";
export const SUBJECT_DELETE_FAILURE = "SUBJECT_DELETE_FAILURE";

export const SEMESTER_ADD_REQUEST = "SEMESTER_ADD_REQUEST";
export const SEMESTER_ADD_SUCCESS = "SEMESTER_ADD_SUCCESS";
export const SEMESTER_ADD_FAILURE = "SEMESTER_ADD_FAILURE";

export const SEMESTER_GET_REQUEST = "SEMESTER_GET_REQUEST";
export const SEMESTER_GET_SUCCESS = "SEMESTER_GET_SUCCESS";
export const SEMESTER_GET_FAILURE = "SEMESTER_GET_FAILURE";

export const SEMESTER_UPDATE_REQUEST = "SEMESTER_UPDATE_REQUEST";
export const SEMESTER_UPDATE_SUCCESS = "SEMESTER_UPDATE_SUCCESS";
export const SEMESTER_UPDATE_FAILURE = "SEMESTER_UPDATE_FAILURE";

export const SEMESTER_DELETE_REQUEST = "SEMESTER_DELETE_REQUEST";
export const SEMESTER_DELETE_SUCCESS = "SEMESTER_DELETE_SUCCESS";
export const SEMESTER_DELETE_FAILURE = "SEMESTER_DELETE_FAILURE";

export const USER_FETCH_FAILURE = "USER_FETCH_FAILURE";
export const USER_FETCH_SUCCESS = "USER_FETCH_SUCCESS";
export const USER_FETCH_REQUEST = "USER_FETCH_REQUEST";

export const GROUP_ADD_REQUEST = "GROUP_ADD_REQUEST";
export const GROUP_ADD_SUCCESS = "GROUP_ADD_SUCCESS";
export const GROUP_ADD_FAILURE = "GROUP_ADD_FAILURE";

export const GROUP_GET_REQUEST = "GROUP_GET_REQUEST";
export const GROUP_GET_SUCCESS = "GROUP_GET_SUCCESS";
export const GROUP_GET_FAILURE = "GROUP_GET_FAILURE";

export const STUDENT_ADD_REQUEST = "STUDENT_ADD_REQUEST";
export const STUDENT_ADD_SUCCESS = "STUDENT_ADD_SUCCESS";
export const STUDENT_ADD_FAILURE = "STUDENT_ADD_FAILURE";

export const STUDENT_GET_REQUEST = "STUDENT_GET_REQUEST";
export const STUDENT_GET_SUCCESS = "STUDENT_GET_SUCCESS";
export const STUDENT_GET_FAILURE = "STUDENT_GET_FAILURE";

export const STUDENT_UPDATE_REQUEST = "STUDENT_UPDATE_REQUEST";
export const STUDENT_UPDATE_SUCCESS = "STUDENT_UPDATE_SUCCESS";
export const STUDENT_UPDATE_FAILURE = "STUDENT_UPDATE_FAILURE";

export const STUDENT_DELETE_REQUEST = "STUDENT_DELETE_REQUEST";
export const STUDENT_DELETE_SUCCESS = "STUDENT_DELETE_SUCCESS";
export const STUDENT_DELETE_FAILURE = "STUDENT_DELETE_FAILURE";

export const TEACHER_ADD_REQUEST = "TEACHER_ADD_REQUEST";
export const TEACHER_ADD_SUCCESS = "TEACHER_ADD_SUCCESS";
export const TEACHER_ADD_FAILURE = "TEACHER_ADD_FAILURE";

export const TEACHER_GET_REQUEST = "TEACHER_GET_REQUEST";
export const TEACHER_GET_SUCCESS = "TEACHER_GET_SUCCESS";
export const TEACHER_GET_FAILURE = "TEACHER_GET_FAILURE";

export const TEACHER_DELETE_REQUEST = "TEACHER_DELETE_REQUEST";
export const TEACHER_DELETE_SUCCESS = "TEACHER_DELETE_SUCCESS";
export const TEACHER_DELETE_FAILURE = "TEACHER_DELETE_FAILURE";

export const TEACHER_UPDATE_REQUEST = "TEACHER_UPDATE_REQUEST";
export const TEACHER_UPDATE_SUCCESS = "TEACHER_UPDATE_SUCCESS";
export const TEACHER_UPDATE_FAILURE = "TEACHER_UPDATE_FAILURE";

export const STUDENT_ATTENDANCE_ADD_REQUEST = "STUDENT_ATTENDANCE_ADD_REQUEST";
export const STUDENT_ATTENDANCE_ADD_SUCCESS = "STUDENT_ATTENDANCE_ADD_SUCCESS";
export const STUDENT_ATTENDANCE_ADD_FAILURE = "STUDENT_ATTENDANCE_ADD_FAILURE";

export const STUDENT_ATTENDANCE_GET_REQUEST = "STUDENT_ATTENDANCE_GET_REQUEST";
export const STUDENT_ATTENDANCE_GET_SUCCESS = "STUDENT_ATTENDANCE_GET_SUCCESS";
export const STUDENT_ATTENDANCE_GET_FAILURE = "STUDENT_ATTENDANCE_GET_FAILURE";

export const TODAY_STUDENT_ATTENDANCE_GET_REQUEST =
  "TODAY_STUDENT_ATTENDANCE_GET_REQUEST";
export const TODAY_STUDENT_ATTENDANCE_GET_SUCCESS =
  "TODAY_STUDENT_ATTENDANCE_GET_SUCCESS";
export const TODAY_STUDENT_ATTENDANCE_GET_FAILURE =
  "TODAY_STUDENT_ATTENDANCE_GET_FAILURE";

export const MONTHLY_ATTENDANCE_GET_REQUEST = "MONTHLY_ATTENDANCE_GET_REQUEST";
export const MONTHLY_ATTENDANCE_GET_SUCCESS = "MONTHLY_ATTENDANCE_GET_SUCCESS";
export const MONTHLY_ATTENDANCE_GET_FAILURE = "MONTHLY_ATTENDANCE_GET_FAILURE";

export const STUDENT_ATTENDANCE_DELETE_REQUEST =
  "STUDENT_ATTENDANCE_DELETE_REQUEST";
export const STUDENT_ATTENDANCE_DELETE_SUCCESS =
  "STUDENT_ATTENDANCE_DELETE_SUCCESS";
export const STUDENT_ATTENDANCE_DELETE_FAILURE =
  "STUDENT_ATTENDENCE_DELETE_FAILURE";

export const TEACHER_ATTENDANCE_ADD_REQUEST = "TEACHER_ATTENDANCE_ADD_REQUEST";
export const TEACHER_ATTENDANCE_ADD_SUCCESS = "TEACHER_ATTENDANCE_ADD_SUCCESS";
export const TEACHER_ATTENDANCE_ADD_FAILURE = "TEACHER_ATTENDANCE_ADD_FAILURE";

export const TEACHER_ATTENDANCE_GET_REQUEST = "TEACHER_ATTENDANCE_GET_REQUEST";
export const TEACHER_ATTENDANCE_GET_SUCCESS = "TEACHER_ATTENDANCE_GET_SUCCESS";
export const TEACHER_ATTENDANCE_GET_FAILURE = "TEACHER_ATTENDANCE_GET_FAILURE";

export const TEACHER_ATTENDANCE_DELETE_REQUEST =
  "TEACHER_ATTENDANCE_DELETE_REQUEST";
export const TEACHER_ATTENDANCE_DELETE_SUCCESS =
  "TEACHER_ATTENDANCE_DELETE_SUCCESS";
export const TEACHER_ATTENDANCE_DELETE_FAILURE =
  "TEACHER_ATTENDENCE_DELETE_FAILURE";

export const SINGLE_STUDENT_ATTENDANCE_GET_REQUEST =
  "SINGLE_STUDENT_ATTENDANCE_GET_REQUEST";
export const SINGLE_STUDENT_ATTENDANCE_GET_SUCCESS =
  "SINGLE_STUDENT_ATTENDANCE_GET_SUCCESS";
export const SINGLE_STUDENT_ATTENDANCE_GET_FAILURE =
  "SINGLE_STUDENT_ATTENDANCE_GET_FAILURE";

export const SINGLE_TEACHER_ATTENDANCE_GET_REQUEST =
  "SINGLE_TEACHER_ATTENDANCE_GET_REQUEST";
export const SINGLE_TEACHER_ATTENDANCE_GET_SUCCESS =
  "SINGLE_TEACHER_ATTENDANCE_GET_SUCCESS";
export const SINGLE_TEACHER_ATTENDANCE_GET_FAILURE =
  "SINGLE_TEACHER_ATTENDANCE_GET_FAILURE";

export const BIOMETRIC_GET_REQUEST = "BIOMETRIC_GET_REQUEST";
export const BIOMETRIC_GET_SUCCESS = "BIOMETRIC_GET_SUCCESS";
export const BIOMETRIC_GET_FAILURE = "BIOMETRIC_GET_FAILURE";

export const DASHBOARD_GET_REQUEST = "DASHBOARD_GET_REQUEST";
export const DASHBOARD_GET_FAILURE = "DASHBOARD_GET_FAILURE";
export const DASHBOARD_GET_SUCCESS = "DASHBOARD_GET_SUCCESS";
